$primary-color: #3c9;
$text-color: #333;
$body-background: #fff;
$tag-background: #eee;
$section-header-color: #999;
$theme-border: 4px solid $primary-color;

body {
    height: 100vh;
    justify-content: center;
    background-color: #292929;

    #logo {
        width: calc(min(60vw, 60vh));
        height: calc(min(60vw, 60vh));
    }
}

@mixin tag-list() {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 15px 0 0;

    > li {
        background: $tag-background;
        display: block;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px 10px;
        font-size: 12px;
        @media print {
            padding: 0;
            margin-top: -10px;
            margin-bottom: 0;
            background: transparent;
            &:not(:last-child)::after {
                content: ",";
            }
        }
    }
}

* {
    box-sizing: border-box;
}



body {
    padding: 0;
    background: $body-background;
    color: $text-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    > * {
        padding: 24px 32px;
        width: 100%;

        @media print {
            padding: 12px 32px;
        }
    }
    > section {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;

        @media print {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
    > section:not(:last-child) {
        border-bottom: $theme-border;
        @media print {
            border-bottom: none;
        }
    }
    > section > h2 {
        text-transform: uppercase;
        color: $section-header-color;
        font-weight: 400;
    }
}

.header {
    color: $primary-color;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: $theme-border;

    &__name {
        font-weight: 400;
        margin-bottom: 12px;
    }
    &__details {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 12px;

        > a {
            color: $primary-color;
            text-decoration: none;
        }
    }
    &__detail {
        margin-top: 4px;
        text-align: center;
    }
    &__detail-section {
        white-space: pre;
    }
    @media print {
        min-height: 0px;
    }
}

.experience,
.education {
    padding: 6px 24px;
    width: 100%;

    @media print {
        padding: 12px 24px;
    }
    > header > h3 {
        margin: 0;
    }
    &__time {
        font-size: 12px;
        padding-top: 2px;
    }
}
.experience {
    &__job {
        font-weight: 400;
    }
    &__title,
    &__company {
        font-weight: 600;
    }
    &__projects {
        border-left: $theme-border;
        padding-left: 20px;
    }
}

.project {
    margin-top: 24px;
    > h4 {
        margin: 0;
    }
    > p {
        font-size: 14px;
    }
}

.experience,
.project {
    &__technologies {
        @include tag-list();
    }
}
